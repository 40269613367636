<template>
  <div class="form-container">
    <h2 class="mb-3">Create a New Issue</h2>

    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <b-form-group 
        id="input-group-1"
        label="Title"
        label-for="input-1"
        label-cols-md="2"
        description="A short headline for the issue.">
        <b-form-input
          id="input-1"
          v-model="form.title"
          required
          maxlength=180
        ></b-form-input>

      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Type"
        label-for="input-2"
        label-cols-md="2">
        <b-form-select
          id="input-2"
          v-model="form.issue_type"
          :options="issue_types"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="Platforms"
        label-cols-md="2"
      >
        <multiselect v-model="form.platforms" :options="platforms" :searchable="false" :close-on-select="false" :show-labels="false" :multiple="true" placeholder="Select all affected platforms"></multiselect>
      </b-form-group>

      <div class="clearfix">
        <b-button type="submit" variant="primary" class="mx-1 float-right">Submit</b-button>
        <b-button type="reset" variant="danger" class="mx-1 float-right">Reset</b-button>    
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    data() {
      return {
        form: {
          title: '',
          platform: null,
          issue_type: null,
          platforms: null
        },
        platforms: ['Xbox One Base', 'Xbox One S', 'Xbox One X', 'PS4 Base', 'PS4 Pro'],
        issue_types: [{ text: 'Select One', value: null }, 'networking', 'crash', 'gameplay', 'performance', 'balance', 'user interface'],
        show: true,
      }
    },
    methods: {
      async onSubmit(evt) {
        const result = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_ROOT}/admin/issue/new`,
          data: this.form,
          headers: {
            'x-auth-token': this.$store.getters.sessionToken
          }
        });
        this.$router.push({ name: 'AdminHome', params: { message: "Thanks for your report!" } })
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.form.title = '',
        this.form.platform = null,
        this.form.description = '',
        this.form.repro_steps = '',
        this.form.platform_username = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      validateForm() {

      }
    },
    created() {
      this.form.issue_id = this.$route.params.issue_id;
      this.form.title = this.$route.params.title;
    },
  }
</script>

<style scoped lang="scss">
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.form-container {
  background-color: white;
  padding: 30px;
}

</style>